import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";
import { Modal } from "antd";

export const getVehicleList = createAsyncThunk(
    "/getVehicleList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/masters/vehicle/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getVehicleByID = createAsyncThunk(
    "/getVehicleByID",
    async (payload) => {
        try {
            const response = await getList("/masters/vehicle/" + payload + "/");
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);
export const addVehicle = createAsyncThunk(
    "/addVehicle",
    async (payload) => {

        try {
            if (payload.id == 0) {
                const response = await postAdd("/masters/vehicle/", payload);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Vehicle Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    return { response, modal: false };
                }
                else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Vehicle. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } 
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

// export const getlotByCompLocWarhouse = createAsyncThunk(
//     "/getlotByCompLocWarhouse",
//     async (payload) => {
//         try {
//             const response = await getList(`/masters/lot/?company=${payload.companyId}&location=${payload.locationId}&warehouse=${payload.warehouseId}`);
//             return response.data;
//         } catch (error) {
//             throw error.message;
//         }
//     }
// );

// export const getlotByLocWarhouse = createAsyncThunk(
//     "/getlotByLocWarhouse",
//     async (payload) => {
//         try {
//             const response = await getList(`/masters/lot/?location=${payload.locationId}&warehouse=${payload.warehouseId}`);
//             return response.data;
//         } catch (error) {
//             throw error.message;
//         }
//     }
// );
// export const getlotByWarhouse = createAsyncThunk(
//     "/getlotByWarhouse",
//     async (payload) => {
//         try {
//             const response = await getList(`/masters/lot/?warehouse=${payload}`);
//             return response.data;
//         } catch (error) {
//             throw error.message;
//         }
//     }
// );