import { createSlice } from "@reduxjs/toolkit";
import { PackingAdd, PackingEdit, getPackingByID, getPackingList, PackingDelete } from "./packingAction";

const packingSlice = createSlice({
    name: "packing",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        packinglist: [],
        productioninitems: [],
        productionoutitems:[],
        packingParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        RFPlistItemCount:0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        RFPpageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        editPackingItems: {},
        editPackingRFPItems: {},
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    productionin:{
                        ...state.rowdata.productionin,
                        [action.payload.key]: action.payload.value,
                    }
                },
            };
        },
        RFPInputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    productionout:{
                        ...state.rowdata.productionout,
                        [action.payload.key]: action.payload.value,
                    }
                },
            };
        },
        SetPackingParams: (state, action) => {
            return {
                ...state,
                packingParams: {
                    ...state.packingParams,
                    ...action.payload
                },
            };
        },
        SetPackingRFPParams: (state, action) => {
            return {
                ...state,
                RFPpageParams: {
                    ...state.RFPpageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setPackingItemsList: (state, action) => {
            return {
                ...state,
                productioninitems: [...state.productioninitems, action.payload]
            }
        },
        setPackingRFPItemsList: (state, action) => {
            return {
                ...state,
                productionoutitems: [...state.productionoutitems, action.payload]
            }
        },
        setEditPackingItemsList: (state, action) => {
            return {
                ...state,
                productioninitems: state.productioninitems?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        setEditPackingRFPItemsList: (state, action) => {
            return {
                ...state,
                productionoutitems: state.productionoutitems?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                productioninitems: state.productioninitems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        removeRFPItems: (state, action) => {
            return {
                ...state,
                productionoutitems: state.productionoutitems.map((e) => {
                    console.log("dfsd",e)
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditPackingItems: (state, action) => {
            return {
                ...state,
                editPackingItems: action.payload,
            };
        },
        setEditPackingRFPItems: (state, action) => {
            return {
                ...state,
                editPackingRFPItems: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackingList.pending, (state, action) => {
                state.status = "getPackingList loading";
                state.loading = true;
            })
            .addCase(getPackingList.fulfilled, (state, action) => {
                state.status = "getPackingList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.packingParams?.page_size
                );

                state.packinglist = response.results;
                state.listCount = response.count;
                state.packingParams = {
                    ...state.packingParams,
                    no_of_pages: noofpages,
                };
                state.rowdata={};
                state.productioninitems = [];
                state.productionoutitems = [];
            })
            .addCase(getPackingList.rejected, (state, action) => {
                state.status = "getPackingList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getPackingByID.pending, (state, action) => {
                state.status = "getPackingByID loading";
                state.loading = true;
            })
            .addCase(getPackingByID.fulfilled, (state, action) => {
                state.status = "getPackingByID succeeded";
                state.loading = false;
                state.rowdata = action.payload;
                state.productioninitems = state.rowdata.productionin?.productioninitems
                state.productionoutitems = state.rowdata.productionout?.productionoutitems
                state.listItemCount = action.payload.productionin?.productioninitems?.length;
                state.RFPlistItemCount = action.payload.productionout?.productionoutitems?.length;
            }
            )
            .addCase(getPackingByID.rejected, (state, action) => {
                state.status = "getPackingByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(PackingAdd.pending, (state, action) => {
                state.status = "PackingAdd loading";
                state.loading = true;
            })
            .addCase(PackingAdd.fulfilled, (state, action) => {
                state.status = "PackingAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.productioninitems = [];
                state.productionoutitems = [];
            }
            )
            .addCase(PackingAdd.rejected, (state, action) => {
                state.status = "PackingAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(PackingEdit.pending, (state, action) => {
                state.status = "PackingEdit loading";
                state.loading = true;
            })
            .addCase(PackingEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "PackingEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.productioninitems = [];
                state.productionoutitems = [];
            })
            .addCase(PackingEdit.rejected, (state, action) => {
                state.status = "PackingEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(PackingDelete.pending, (state, action) => {
                state.status = "PackingDelete loading";
                state.loading = true;
            })
            .addCase(PackingDelete.fulfilled, (state, action) => {
                state.status = "PackingDelete succeeded";
                state.loading = false
            }
            )
            .addCase(PackingDelete.rejected, (state, action) => {
                state.status = "PackingDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetPackingParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setPackingItemsList,
    clearuploadImage,
    setUploadImage,
    setEditPackingItems,
    setEditPackingItemsList,
    removeItems,
    setEditPackingRFPItems,
    setEditPackingRFPItemsList,
    SetPackingRFPParams,
    removeRFPItems,setPackingRFPItemsList,RFPInputChangeValue 
} = packingSlice.actions;

export default packingSlice.reducer;