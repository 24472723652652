import { createSlice } from "@reduxjs/toolkit";
import {
    getInternalTransferInByID, getInternalTransferInList, internalTransferInAdd, internalTransferInEdit, internalTransferInDelete,
    internalTransferInAttachments,
    getInternalTransferOutListForIn,
    getInternalTransferOutByIDforIn
} from './internalTransferInAction';

const internalTransferInSlice = createSlice({
    name: "internaltransferin",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        showCamera: false,
        internalTransferInImages: [],
        internalTransferInlist: [],
        internalTransferOutlistFroIn: [],
        internal_transfer_in_items: [],
        internaltransferoutitemsForIn: [],
        listItemCountForIn: 0,
        internalTransferInParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        internalTransferOutParamsForIn: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        editTransferItems: {}
    },
    reducers: {
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        InputChangeValueCompany: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    deliveryorder: {
                        ...state.rowdata.deliveryorder,
                        [action.payload.key]: action.payload.value,
                    }
                },
            };
        },
        SetInternalTransferInParams: (state, action) => {
            return {
                ...state,
                internalTransferInParams: {
                    ...state.internalTransferInParams,
                    ...action.payload
                },
            };
        },
        SetInternalTransferInParamsForIn: (state, action) => {
            return {
                ...state,
                internalTransferOutParamsForIn: {
                    ...state.internalTransferOutParamsForIn,
                    ...action.payload
                },
            };
        },
        SetInternalTransferInItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setItemsList: (state, action) => {
            return {
                ...state,
                internal_transfer_in_items: [...state.internal_transfer_in_items, action.payload]
            }
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                rowdata: action.payload,
            }
        },
        setShowCamera: (state, action) => {
            return {
                ...state,
                showCamera: action.payload,
            }
        },
        setInternalTransferInImages: (state, action) => {
            return {
                ...state,
                internalTransferInImages:
                    [
                        action.payload?.primary_loading_image,
                        action.payload?.primary_unloading_image,
                        action.payload?.secondary_loading_image,
                        action.payload?.secondary_unloading_image,
                    ]
            }
        },
        clearImages: (state, action) => {
            state.internalTransferInImages = [];
        },
        removeImages: (state, action) => {
            return {
                ...state,
                internalTransferInImages: state.internalTransferInImages?.filter(
                    (e) => e.id != action.payload
                ),
            };
        },
        setEditTransferItemsList: (state, action) => {
            return {
                ...state,
                internal_transfer_in_items: state.internal_transfer_in_items?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                internal_transfer_in_items: state.internal_transfer_in_items.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditTransferItems: (state, action) => {
            return {
                ...state,
                editTransferItems: action.payload,
            };
        },
        removeNullValuesFromImages: (state, action) => {
            return {
                ...state,
                internalTransferInImages: state.internalTransferInImages?.filter(item => item !== null)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInternalTransferInList.pending, (state, action) => {
                state.status = " loading";
                state.loading = true;
            })
            .addCase(getInternalTransferInList.fulfilled, (state, action) => {
                state.status = "getInternalTransferInList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleInwardParams?.page_size
                );

                state.internalTransferInlist = response.results;
                state.listCount = response.count;
                state.internalTransferInParams = {
                    ...state.internalTransferInParams,
                    no_of_pages: noofpages,
                };
                state.rowdata = {};
                state.internalTransferInImages = [];
                state.internal_transfer_in_items = [];
            })
            .addCase(getInternalTransferOutListForIn.pending, (state, action) => {
                    state.status = " loading";
                    state.loading = true;
                })
            .addCase(getInternalTransferOutListForIn.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutListForIn succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(action.payload.count / state.vehicleInwardParams?.page_size);
            
                // Extract only `id` and `code` from each item in response.results
                state.internalTransferOutlistFroIn = response.results.map(item => ({
                    id: item.id,
                    code: item.code
                }));
            
                state.listCount = response.count;
                state.internalTransferOutParamsForIn = {
                    ...state.internalTransferOutParamsForIn,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getInternalTransferOutListForIn.rejected, (state, action) => {
                state.status = "getInternalTransferOutListForIn failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getInternalTransferOutByIDforIn.pending, (state, action) => {
                state.status = "getInternalTransferOutByIDforIn loading";
                state.loading = true;
            })
            .addCase(getInternalTransferOutByIDforIn.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutByIDforIn succeeded";
                state.loading = false;
                state.rowdata = action.payload;
                state.internal_transfer_in_items = state.rowdata?.internaltransferoutitems;
                state.listItemCount = action.payload.internaltransferoutitems?.length;
                state.loading = false;
                // const payload = action.payload;
                // state.rowdata = {

                //     id: payload.id,
                //     code: payload.code,
                //     company: payload.company,
                //     location: payload.location,
                //     warehouse: payload.internaltransferoutitems?.length > 0
                //         ? payload.internaltransferoutitems[0].warehouse
                //         : null,
                //     vehicle: payload.vehicle,
                //     internal_delivery_order_code: payload.internal_delivery_order?.code,
                //     internal_shipment_items: payload.internal_delivery_order?.internal_delivery_order_items?.map(item => ({
                //         id: item.id,
                //         code: item.code,
                //         item: item.item,
                //         quantity: item.quantity,
                //         no_of_packs: item.no_of_packs,
                //         created_on: item.created_on,
                //         modified_on: item.modified_on
                //     })) || [],
                //     created_on: payload.internal_delivery_order?.created_on || payload.created_on,
                //     modified_on: payload.internal_delivery_order?.modified_on || payload.modified_on
                // };

                // state.internaltransferoutitemsForIn = state.rowdata.internal_shipment_items;
                // state.listItemCountForIn = state.rowdata.internal_shipment_items.length;
            })

            .addCase(getInternalTransferOutByIDforIn.rejected, (state, action) => {
                state.status = "getInternalTransferOutByIDforIn failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getInternalTransferInList.rejected, (state, action) => {
                state.status = "getInternalTransferInList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getInternalTransferInByID.pending, (state, action) => {
                state.status = "getInternalTransferInByID loading";
                state.loading = true;
            })
            .addCase(getInternalTransferInByID.fulfilled, (state, action) => {
                state.status = "getInternalTransferInByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.internal_transfer_in_items = state.rowdata?.internal_transfer_in_items;
                state.listItemCount = action.payload.internal_transfer_in_items?.length;
                state.internalTransferInImages = state.internalTransferInImages?.filter(item => item !== null)
            }
            )
            .addCase(getInternalTransferInByID.rejected, (state, action) => {
                state.status = "getInternalTransferInByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferInAdd.pending, (state, action) => {
                state.status = "internalTransferInAdd loading";
                state.loading = true;
            })
            .addCase(internalTransferInAdd.fulfilled, (state, action) => {
                state.status = "internalTransferInAdd succeeded";
                state.loading = false;
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.internal_transfer_in_items = [];
            }
            )
            .addCase(internalTransferInAdd.rejected, (state, action) => {
                state.status = "internalTransferInAdd failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferInEdit.pending, (state, action) => {
                state.status = "internalTransferInEdit loading";
                state.loading = true;
            })
            .addCase(internalTransferInEdit.fulfilled, (state, action) => {
                state.status = "internalTransferInEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
            })
            .addCase(internalTransferInEdit.rejected, (state, action) => {
                state.status = "internalTransferInEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferInDelete.pending, (state, action) => {
                state.status = "internalTransferInDelete loading";
                state.loading = true;
            })
            .addCase(internalTransferInDelete.fulfilled, (state, action) => {
                state.status = "internalTransferInDelete succeeded";
                state.loading = false
            }
            )
            .addCase(internalTransferInDelete.rejected, (state, action) => {
                state.status = "internalTransferInDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferInAttachments.pending, (state, action) => {
                state.status = "loading";
                state.loading = true;
            })
            .addCase(internalTransferInAttachments.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.status = "succeeded";
                state.loading = false;
                state.internalTransferInImages = [...state.internalTransferInImages, response];
            })
            .addCase(internalTransferInAttachments.rejected, (state, action) => {
                state.status = "failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetInternalTransferInParams,
    isModelVisible,
    isDrawerVisible,
    setItemsList,
    setAutoFillItems,
    setShowCamera,
    setInternalTransferInImages,
    clearImages,
    removeImages,
    InputChangeValueCompany,
    SetInternalTransferInItemParams,
    setEditTransferItemsList,
    removeItems,
    changeParams,
    setEditTransferItems,
    removeNullValuesFromImages,
    SetInternalTransferInParamsForIn
} = internalTransferInSlice.actions;

export default internalTransferInSlice.reducer;