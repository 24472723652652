import { createSlice } from "@reduxjs/toolkit";
import { getLotTransferIn, getLotTransferInByID, getLotTransferInList, LotTransferInAdd, LotTransferInDelete,LotTransferInEdit } from "./lotTransferInAction";

const lotTransferInSlice = createSlice({
    name: "lotTransferin",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        balance_quantity:0,
        available_quantity:0,
        lotTransferInlist: [],
        lottransferinitems:[],
        LotTransferInList: [],
        lotTransferInParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        LotTransferInParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        listItemCount: 0,
        pageParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        editLotTransferInItems: {}
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetLotTransferInParams: (state, action) => {
            return {
                ...state,
                lotTransferInParams: {
                    ...state.lotTransferInParams,
                    ...action.payload
                },
            };
        },
        SetLotTransferInItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setLotItemsList: (state, action) => {
            const updatedPayload = {
                ...action.payload.initialSet,
                jattu: null, // Setting jattu as empty
                jattuactivity: null // Setting jattuactivity as empty
            };
            if(action.payload?.initialSet){
                return {
                    ...state,
                    lottransferinitems: [...state.lottransferinitems, updatedPayload]
                };
            }
            else{
            return {
                ...state,
                lottransferinitems: [...state.lottransferinitems, action.payload]
            }
        }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                lottransferinitems: state.lottransferinitems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditLotTransferInItems: (state, action) => {
            return {
                ...state,
                editLotTransferInItems: action.payload,
            };
        },
        setLotTransferInParams: (state, action) => {
            return {
              ...state,
              LotTransferInParams: action.payload,
            };
          },
        setEditLotTransferInItemsList: (state, action) => {
            return {
                ...state,
                lottransferinitems: state.lottransferinitems.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                rowdata : action.payload,
            }
        },
        setBalanceQuantity: (state, action) => {
            return {
                ...state,
                balance_quantity: action.payload,
            }
        },
        setAvaliableQuantity: (state, action) => {
            return {
                ...state,
                available_quantity: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLotTransferInList.pending, (state, action) => {
                state.status = "getLotTransferInList loading";
                state.loading = true;
            })
            .addCase(getLotTransferInList.fulfilled, (state, action) => {
                state.status = "getLotTransferInList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.lotTransferInParams?.page_size
                );

                state.lotTransferInlist = response.results;
                state.listCount = response.count;
                state.lotTransferInParams = {
                    ...state.lotTransferInParams,
                    no_of_pages: noofpages,
                };
                state.lottransferinitems = [];
            })
            .addCase(getLotTransferInList.rejected, (state, action) => {
                state.status = "getLotTransferInList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLotTransferInByID.pending, (state, action) => {
                state.status = "getLotTransferInByID loading";
                state.loading = true;
            })
            .addCase(getLotTransferInByID.fulfilled, (state, action) => {
                state.status = "getLotTransferInByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.lottransferinitems = state.rowdata?.lottransferinitems;
                state.listItemCount = action.payload.lottransferinitems?.length;
            }
            )
            .addCase(getLotTransferInByID.rejected, (state, action) => {
                state.status = "getLotTransferInByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferInAdd.pending, (state, action) => {
                state.status = "LotTransferInAdd loading";
                state.loading = true;
            })
            .addCase(LotTransferInAdd.fulfilled, (state, action) => {
                state.status = "LotTransferInAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.lottransferinitems = [];
            }
            )
            .addCase(LotTransferInAdd.rejected, (state, action) => {
                state.status = "LotTransferInAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(LotTransferInEdit.pending, (state, action) => {
                state.status = "LotTransferInEdit loading";
                state.loading = true;
            })
            .addCase(LotTransferInEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "LotTransferInEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.lottransferinitems = [];
            })
            .addCase(LotTransferInEdit.rejected, (state, action) => {
                state.status = "LotTransferInEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferInDelete.pending, (state, action) => {
                state.status = "LotTransferInDelete loading";
                state.loading = true;
            })
            .addCase(LotTransferInDelete.fulfilled, (state, action) => {
                state.status = "LotTransferInDelete succeeded";
                state.loading = false
            }
            )
            .addCase(LotTransferInDelete.rejected, (state, action) => {
                state.status = "LotTransferInDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getLotTransferIn.pending, (state, action) => {
                state.status = "getLotTransferIn loading";
                // state.miniLotTransferInLoading = true;
                })
                .addCase(getLotTransferIn.fulfilled, (state, action) => {
                state.status = "getLotTransferIn succeeded";
                var response = action.payload.response;
                var params = action.payload.params;
                var list = [];
                if (params?.page == 1) {
                    list = response.results;
                } else {
                    list = [...response.results];
                }
                var noofpages = Math.ceil(
                    response.count / state.LotTransferInParams?.page_size
                );
                state.LotTransferInList = list;
                // state.miniLotTransferInLoading = false;
                state.LotTransferInParams = {
                    ...state.LotTransferInParams,
                    no_of_pages: noofpages,
                };
                })
                .addCase(getLotTransferIn.rejected, (state, action) => {
                state.status = "getLotTransferIn failed";
                // state.miniLotTransferInLoading = false;
                state.error = action.error.message;
                })
    }

});
export const {
    InputChangeValue,
    SetLotTransferInParams,
    isModelVisible,
    isDrawerVisible,
    setLotItemsList,
    removeItems,
    changeParams,
    setEditLotTransferInItems,
    setEditLotTransferInItemsList,
    SetLotTransferInItemParams,
    setAutoFillItems,
    setBalanceQuantity,
    setAvaliableQuantity,
    setLotTransferInParams
} = lotTransferInSlice.actions;

export default lotTransferInSlice.reducer;