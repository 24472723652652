import { lazy } from "react";

const PagesRoutes = [
	{
		path: "/login",
		component: lazy(() =>
			import("../../view/pages/authentication/login/username-login")
		),
		layout: "FullLayout",
	},
	{
		path: "/delete-account",
		component: lazy(() =>
			import("../../view/pages/authentication/deleteAcount/index")
		),
		layout: "FullLayout",
	},

	//masters
	{
		path: "/Masters",
		component: lazy(() => import("../../view/pages/masters")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/jattu",
		component: lazy(() => import("../../view/pages/masters/jattu")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/jattuactivity",
		component: lazy(() => import("../../view/pages/masters/jattuactivity")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/company",
		component: lazy(() => import("../../view/pages/masters/company")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/truckSheet",
		component: lazy(() => import("../../view/pages/masters/truckSheet")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/stock_document",
		component: lazy(() => import("../../view/pages/masters/stockDocument")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/units",
		component: lazy(() => import("../../view/pages/masters/units")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/locations",
		component: lazy(() => import("../../view/pages/masters/locations")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/work_type",
		component: lazy(() => import("../../view/pages/masters/worktype")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/party",
		component: lazy(() => import("../../view/pages/masters/party")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/warehouse",
		component: lazy(() => import("../../view/pages/masters/warehouse")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/lot",
		component: lazy(() => import("../../view/pages/masters/lot")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/item",
		component: lazy(() => import("../../view/pages/masters/Item")),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/Masters/deliveryordernumber",
		component: lazy(() => import("../../view/pages/masters/deliveryordernumber")),
		layout: "VerticalLayout",
		exact: true,
	},
	// errors 
	{
		path: "/pages/error-page-404",
		component: lazy(() => import("../../view/pages/error/404/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-403",
		component: lazy(() => import("../../view/pages/error/403/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-500",
		component: lazy(() => import("../../view/pages/error/500/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-502",
		component: lazy(() => import("../../view/pages/error/502/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/error-page-503",
		component: lazy(() => import("../../view/pages/error/503/index")),
		layout: "FullLayout",
	},
	{
		path: "/pages/blank-page",
		component: lazy(() => import("../../view/pages/blank")),
		layout: "VerticalLayout",
	},

	//  Side Menu


	// Vehicle Inward
	{
		path: "/vehicle_inward",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleInward/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_inward/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleInward/addVehicleInward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_inward/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleInward/addVehicleInward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_inward/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleInward/viewVehicleInward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},

	// Vehicle unloading
	{
		path: "/vehicle_unloading",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleUnloading")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_unloading/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleUnloading/viewVehicleUnloading.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_unloading/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleUnloading/addVehicleUnloading.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicle_unloading/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleUnloading/addVehicleUnloading.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},


	// {
	// 	path: "/driver",
	// 	component: lazy(() =>
	// 		import("../../view/pages/Sidemenu/Driver/index")
	// 	),
	// 	layout: "VerticalLayout",
	// 	exact: true,
	// },
	// {
	// 	path: "/driver/view/:id",
	// 	component: lazy(() =>
	// 		import("../../view/pages/Sidemenu/Driver/DriverView.jsx")
	// 	),
	// 	layout: "VerticalLayout",
	// 	exact: true,
	// },

	//transfers
	{
		path: "/internal_transferout",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferout/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferOut/viewInternalTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferout/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferOut/addInternalTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferout/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferOut/addInternalTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferin",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferin/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferIn/viewInternalTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferin/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferIn/addInternalTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/internal_transferin/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/InternalTransferIn/addInternalTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/internal_transferin",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/InternalTransferIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/lot_transferout",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferout/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferOut/addLotTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferout/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferOut/addLotTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferout/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferOut/viewLotTransferOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/lot_transferin",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferin/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferIn/addLotTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferin/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferIn/addLotTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/lot_transferin/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/LotTransferIn/viewLotTransferIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},

	{
		path: "/stockadjustmentplus",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentPlus/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentplus/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentPlus/addStockAdjustmentPlus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentplus/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentPlus/addStockAdjustmentPlus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentplus/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentPlus/viewStockAdjustmentPlus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentminus",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentMinus/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentminus/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentMinus/viewStockAdjustmentMinus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentminus/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentMinus/addStockAdjustmentMinus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/stockadjustmentminus/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/StockAdjustmentMinus/addStockAdjustmentMinus.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/otherjattuactivities",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/OtherJattuActivities/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/otherjattuactivities/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/OtherJattuActivities/addOtherJattuActivities.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/otherjattuactivities/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/OtherJattuActivities/viewOtherJattuActivities.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/otherjattuactivities/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/OtherJattuActivities/addOtherJattuActivities.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},

	//production
	{
		path: "/production_in",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_in/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionIn/addProductionIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_in/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionIn/viewProductionIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_in/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionIn/addProductionIn.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_out",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_out/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionOut/addProductionOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_out/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionOut/viewProductionOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/production_out/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/ProductionOut/addProductionOut.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicleoutward",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleOutward/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicleoutward/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleOutward/addVehicleOutward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicleoutward/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleOutward/viewVehicleOutward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/vehicleoutward/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/VehicleOutward/addVehicleOutward.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/packing",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Packing/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/packing/add",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Packing/addPacking.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/packing/view/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Packing/viewPacking.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/packing/edit/:id",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Packing/addPacking.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/vehicle_inward",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/VehicleInward/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/vehicle_unloading",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/VehicleUnloading/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/lot_transferout",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/LotTransferOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/lot_transferin",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/LotTransferIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/reports/internal_transferout",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/InternalTransferOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/stockadjustmentplus",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/StockAdjustmentPlus/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/stockadjustmentminus",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/StockAdjustmentMinus/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/otherjattuactivities",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/OtherJattuActivities/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/stockbalance",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/StockBalanceByWarehouse/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/jattu_activity",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/JattuActivity/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/day_wise_transfers",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/DaywiseTransfers/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/tally_sheet",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/TallySheet/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/production_in",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ProductionIn/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path:"/reports/stock_movement",
		component : lazy(()=>
		import("../../view/pages/Sidemenu/Reports/StockMovements/index.jsx")),
		layout:"VerticalLayout",
		exact : true,
	},
	{
		path: "/report/production_out",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/ProductionOut/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/vehicleoutward",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/VehicleOutward/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/packing",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Packing/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	{
		path: "/report/stock",
		component: lazy(() =>
			import("../../view/pages/Sidemenu/Reports/Stock/index.jsx")
		),
		layout: "VerticalLayout",
		exact: true,
	},
	// Settings View 
	{
		path: "/pages/settings/",
		component: lazy(() => import("../../view/pages/settings")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/settings/user-details/:id",
		component: lazy(() =>
			import("../../view/pages/settings/ManageUsers/userDetails")
		),
		layout: "VerticalLayout",
	},

	//profile-view
	{
		path: "/pages/userProfile/personel-information",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/activity",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/password-change",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
	{
		path: "/pages/userProfile/user-devices",
		component: lazy(() => import("../../view/pages/userProfile")),
		layout: "VerticalLayout",
	},
];

export default PagesRoutes;
