import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

export const getLotTransferOutList = createAsyncThunk(
    "/getLotTransferOutList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/lottransferout/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getTransfarOutItems = createAsyncThunk( 
	"/getTransfarOutItems",
	async (payload) => {
        if (!payload || !payload.from_warehouse_id || !payload.from_lot_id) {
            throw new Error("Invalid payload: from_warehouse_id or from_lot_id is missing");
        }

        // Extract pagination parameters
        const paginationParams = {
            no_of_pages: payload.no_of_pages,
            page_size: payload.page_size,
            page: payload.page,
            search: payload.search
        };
    
        try {
            const response = await getParamsList(
                `/transfers/lottransferout/items/${payload.from_warehouse_id}/${payload.from_lot_id}/`,
                { ...paginationParams }
            );
    
            if (response) {
                return { response, payload };
            } else {
                throw new Error("Empty response received");
            }
        } catch (error) {
            return error.message;
        }
    }
    
);


export const getLotTransferOutByID = createAsyncThunk(
    "/getLotTransferOutByID",
    async (payload) => {
        try {
            const response = await getList("/transfers/lottransferout/" + payload + "/");
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);

export const LotTransferOutAdd = createAsyncThunk(
    "/LotTransferOutAdd",
    async (payload) => {

        try {
            const response = await postAdd("/transfers/lottransferout/", payload);
            if (response.status === 201) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Lot Transfer Out Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return { response, modal: false };
            }
            else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to add Lot Transfer Out. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                             {error.response.data.error[0]}
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const LotTransferOutEdit = createAsyncThunk(
    "/LotTransferOutEdit",
    async (payload) => {
        try {
            const { obj } = payload;
            const response = await postEdit("/transfers/lottransferout/" + payload.id + "/", obj);
            if (response.status === 200) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Lot Transfer Out updated Successfully.!
                            </p>
                        </div>
                    ),
                });
                return { response, active: false };
            } else {
                throw new Error("Sorry! Unable to update Lot Transfer Out. Please try again!")
            }
        } catch (err) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {err.message}
                        </p>
                    </div>
                ),
            });
            throw err;
        }
    }
);

export const LotTransferOutDelete = createAsyncThunk(
    "/LotTransferOutDelete",
    async (payload, { dispatch }) => {
        try {
            const response = await postDelete("/transfers/lottransferout/" + payload.id + "/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Lot Transfer Out has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Lot Transfer Out has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                             {error.response.data.error[0]}
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const getStockTransfer = createAsyncThunk(
    "/getStockTransfer",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/stock/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);