import { createSlice } from "@reduxjs/toolkit";
import {
	allMenus,
	getMenuItems,
	getUserPermissions,
	postAttachments,
	postNotificationAttachment,
	getExportMenuItems,
	getImportMenuItems,
	getReportsMenuItems,
} from "./systemActions";

export const systemSlice = createSlice({
	name: "system",
	initialState: {
		masterMenuItemsList: [],
		sideMenuItemsList: [],
		reportsLoading: false,
		reportsMenuItemsList: [],
		userAccessList: [],
		quickMenuItemsList: [],
		importMenuItemsList: [],
		profileMenuItemsList: [],
		exportMenuItemsList: [],
		notificationAttachment: {},
		filterData: [],
		attachmentData: [],
		active: false,
		quickMenuactive: false,
		menuactive: false,
		systemFormulasList: [],
	},
	reducers: {
		clearAttachments: (state, action) => {
			state.attachmentData = [];
		},
		removeAttachmentData: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				attachmentData: state.attachmentData.filter(
					(e) => e.id != action.payload
				),
			};
		},
		addAttachmentDataData: (state, action) => {
			return {
				...state,
				attachmentData: action.payload,
			};
		},
		userMenuActive: (state, action) => {
			return {
				...state,
				menuactive: action.payload,
			};
		},
		menuItemSearch: (state, action) => {
			return {
				...state,
				masterMenuItemsList: {
					menuitems: action.payload,
				},
			};
		},
		searchReports: (state, action) => {
			return {
				...state,
				reportsMenuItemsList: {
					menuitems: action.payload,
				},
			};
		},
	},
	extraReducers(builder) {
		builder
			//all menus
			.addCase(allMenus.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(allMenus.fulfilled, (state, action) => {
				const { count, results } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.allMenus.list = results;
				state.allMenus.count = count;
			})
			.addCase(allMenus.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get menus
			.addCase(getMenuItems.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(getMenuItems.fulfilled, (state, action) => {
				const { from, response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				switch (from) {
					case "masters":
						state.masterMenuItemsList = response;
						break;
					case "sidemenu":
						state.sideMenuItemsList = response;
						break;
					case "profilemenu":
						state.profileMenuItemsList = response;
						break;
					case "reports":
						state.reportsMenuItemsList = response;
						break;
				}
			})
			.addCase(getMenuItems.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post Attachmnets
			.addCase(postAttachments.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postAttachments.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.attachmentData = [...state.attachmentData, response];
			})
			.addCase(postAttachments.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//notificationb attachment
			.addCase(postNotificationAttachment.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postNotificationAttachment.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.notificationAttachment = response;
			})
			.addCase(postNotificationAttachment.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getUserPermissions.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(getUserPermissions.fulfilled, (state, action) => {
				state.status = "succeeded";
				// Add any fetched posts to the array
				state.userAccessList = action.payload;
			})
			.addCase(getUserPermissions.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(getExportMenuItems.pending, (state, action) => {
				state.status = "getExportMenuItems loading";
			})
			.addCase(getExportMenuItems.fulfilled, (state, action) => {
				state.status = "getExportMenuItems succeeded";
				// Add any fetched posts to the array
				state.exportMenuItemsList = action.payload;
			})
			.addCase(getExportMenuItems.rejected, (state, action) => {
				state.status = "getExportMenuItems failed";
				state.error = action.error.message;
			})
			.addCase(getImportMenuItems.pending, (state, action) => {
				state.status = "getImportMenuItems loading";
			})
			.addCase(getImportMenuItems.fulfilled, (state, action) => {
				state.status = "getImportMenuItems succeeded";
				// Add any fetched posts to the array
				state.importMenuItemsList = action.payload;
			})
			.addCase(getImportMenuItems.rejected, (state, action) => {
				state.status = "getImportMenuItems failed";
				state.error = action.error.message;
			})
			.addCase(getReportsMenuItems.pending, (state, action) => {
				state.status = "getReportsMenuItems loading";
				state.reportsLoading = true;
			})
			.addCase(getReportsMenuItems.fulfilled, (state, action) => {
				state.status = "getReportsMenuItems succeeded";
				state.reportsLoading = false;
				action.payload.menuitems = action.payload.menuitems.sort(
					(a, b) => (a.sequence > b.sequence ? 1 : -1)
				);
				// Add any fetched posts to the array
				state.reportsMenuItemsList = action.payload;
			})
			.addCase(getReportsMenuItems.rejected, (state, action) => {
				state.status = "getReportsMenuItems failed";
				state.reportsLoading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	userMenuActive,
	clearAttachments,
	menuItemSearch,
	addAttachmentDataData,
	removeAttachmentData,
	searchReports,
} = systemSlice.actions;

export default systemSlice.reducer;
