import { createSlice } from "@reduxjs/toolkit";
import {
    getInternalTransferOutByID, getInternalTransferOutList, internalTransferOutAdd, internalTransferOutEdit, internalTransferOutDelete,
    internalTransferOutAttachments,
    getStockTransferForInternalTransfer
} from './internalTransferOutAction';

const internalTransferOutSlice = createSlice({
    name: "internaltransferout",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        showCamera: false,
        internalTransferOutImages: [],
        internalTransferOutlist: [],
        internaltransferoutitems: [],
        internalTransferOutParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        editTransferItems: {}
    },
    reducers: {
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        InputChangeValueCompany: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    deliveryorder: {
                        ...state.rowdata.deliveryorder,
                        [action.payload.key]: action.payload.value,
                    }
                },
            };
        },
        SetInternalTransferOutParams: (state, action) => {
            return {
                ...state,
                internalTransferOutParams: {
                    ...state.internalTransferOutParams,
                    ...action.payload
                },
            };
        },
        SetInternalTransferOutItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setItemsList: (state, action) => {
            return {
                ...state,
                internaltransferoutitems: [...state.internaltransferoutitems, action.payload]
            }
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                rowdata: action.payload,
            }
        },
        setShowCamera: (state, action) => {
            return {
                ...state,
                showCamera: action.payload,
            }
        },
        setInternalTransferOutImages: (state, action) => {
            return {
                ...state,
                internalTransferOutImages:
                    [
                        action.payload?.primary_loading_image,
                        action.payload?.primary_unloading_image,
                        action.payload?.secondary_loading_image,
                        action.payload?.secondary_unloading_image,
                    ]
            }
        },
        clearImages: (state, action) => {
            state.internalTransferOutImages = [];
        },
        removeImages: (state, action) => {
            return {
                ...state,
                internalTransferOutImages: state.internalTransferOutImages?.filter(
                    (e) => e.id != action.payload
                ),
            };
        },
        setEditTransferItemsList: (state, action) => {
            return {
                ...state,
                internaltransferoutitems: state.internaltransferoutitems?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                internaltransferoutitems: state.internaltransferoutitems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditTransferItems: (state, action) => {
            return {
                ...state,
                editTransferItems: action.payload,
            };
        },
        removeNullValuesFromImages: (state, action) => {
            return {
                ...state,
                internalTransferOutImages: state.internalTransferOutImages?.filter(item => item !== null)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInternalTransferOutList.pending, (state, action) => {
                state.status = " loading";
                state.loading = true;
            })
            .addCase(getInternalTransferOutList.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleInwardParams?.page_size
                );

                state.internalTransferOutlist = response.results;
                state.listCount = response.count;
                state.internalTransferOutParams = {
                    ...state.internalTransferOutParams,
                    no_of_pages: noofpages,
                };
                state.rowdata = {};
                state.internalTransferOutImages = [];
                state.internaltransferoutitems = [];
            })
            .addCase(getInternalTransferOutList.rejected, (state, action) => {
                state.status = "getInternalTransferOutList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getInternalTransferOutByID.pending, (state, action) => {
                state.status = "getInternalTransferOutByID loading";
                state.loading = true;
            })
            .addCase(getInternalTransferOutByID.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.internaltransferoutitems = state.rowdata?.internaltransferoutitems;
                state.listItemCount = action.payload.internaltransferoutitems?.length;
                state.internalTransferOutImages = state.internalTransferOutImages?.filter(item => item !== null)
            }
            )
            .addCase(getInternalTransferOutByID.rejected, (state, action) => {
                state.status = "getInternalTransferOutByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferOutAdd.pending, (state, action) => {
                state.status = "internalTransferOutAdd loading";
                state.loading = true;
            })
            .addCase(internalTransferOutAdd.fulfilled, (state, action) => {
                state.status = "internalTransferOutAdd succeeded";
                state.loading = false;
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.internaltransferoutitems = [];
            }
            )
            .addCase(internalTransferOutAdd.rejected, (state, action) => {
                state.status = "internalTransferOutAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(internalTransferOutEdit.pending, (state, action) => {
                state.status = "internalTransferOutEdit loading";
                state.loading = true;
            })
            .addCase(internalTransferOutEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "internalTransferOutEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
            })
            .addCase(internalTransferOutEdit.rejected, (state, action) => {
                state.status = "internalTransferOutEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferOutDelete.pending, (state, action) => {
                state.status = "internalTransferOutDelete loading";
                state.loading = true;
            })
            .addCase(internalTransferOutDelete.fulfilled, (state, action) => {
                state.status = "internalTransferOutDelete succeeded";
                state.loading = false
            }
            )
            .addCase(internalTransferOutDelete.rejected, (state, action) => {
                state.status = "internalTransferOutDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferOutAttachments.pending, (state, action) => {
                state.status = "loading";
                state.loading = true;
            })
            .addCase(internalTransferOutAttachments.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.status = "succeeded";
                state.loading = false;
                state.internalTransferOutImages = [...state.internalTransferOutImages, response];
            })
            .addCase(internalTransferOutAttachments.rejected, (state, action) => {
                state.status = "failed";
                state.loading = false;
                state.error = action.error.message;
            })
             .addCase(getStockTransferForInternalTransfer.pending, (state, action) => {
                state.status = "getStockTransferForInternalTransfer loading";
                state.loading = true;
            })
             .addCase(getStockTransferForInternalTransfer.fulfilled, (state, action) => {
                state.status = "getStockTransferForInternalTransfer succeeded";
                state.loading = false;
                // state.stockAvailablity = action.payload.response?.quantity;
                state.rowdata = {
                    ...state.rowdata,
                    stockAvailablity: action.payload.response?.quantity
                }
            })
            .addCase(getStockTransferForInternalTransfer.rejected, (state, action) => {
                state.status = "getStockTransferForInternalTransfer failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetInternalTransferOutParams,
    isModelVisible,
    isDrawerVisible,
    setItemsList,
    setAutoFillItems,
    setShowCamera,
    setInternalTransferOutImages,
    clearImages,
    removeImages,
    InputChangeValueCompany,
    SetInternalTransferOutItemParams,
    setEditTransferItemsList,
    removeItems,
    changeParams,
    setEditTransferItems,
    removeNullValuesFromImages
} = internalTransferOutSlice.actions;

export default internalTransferOutSlice.reducer;