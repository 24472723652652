import { createSlice } from "@reduxjs/toolkit";
import { getVehicleList, getVehicleByID, addVehicle, getVehicleByCompLocWarhouse,getVehicleByLocWarhouse,getVehicleByWarhouse } from "./vehicleAction";
import { e } from "mathjs";

const VehicleSlice = createSlice({
    name: "Vehicle",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        Vehiclelist: [],
        locWarehouseList: [],
        VehicleParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        VehicleByCompLocWarehouse: [],
        VehicleByLocWarehouse: [],
        VehicleByWarehouse: [],
    },
    reducers: {
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetVehicleParams: (state, action) => {
            console.log("vehicle params", action.payload);
            return {
                ...state,
                VehicleParams: {
                    ...state.VehicleParams,
                    ...action.payload, // Properly merging payload properties into VehicleParams
                },
            };
        },
        setLocWarehouseList: (state, action) => {
            return {
                ...state,
                locWarehouseList: action.payload,
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleList.pending, (state, action) => {
                state.status = "getVehicleList loading";
                state.loading = true;
            })
            .addCase(getVehicleList.fulfilled, (state, action) => {
                state.status = "getVehicleList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.VehicleParams?.page_size
                );

                state.Vehiclelist = response.results;
                state.listCount = response.count;
                state.VehicleParams = {
                    ...state.VehicleParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getVehicleList.rejected, (state, action) => {
                state.status = "getVehicleList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getVehicleByID.pending, (state, action) => {
                state.status = "getVehicleByID loading";
                state.loading = true;
            })
            .addCase(getVehicleByID.fulfilled, (state, action) => {
                state.status = "getVehicleByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getVehicleByID.rejected, (state, action) => {
                state.status = "getVehicleByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(addVehicle.pending, (state, action) => {
                state.status = "addVehicle loading";
                state.loading = true;
            })
            .addCase(addVehicle.fulfilled, (state, action) => {
                state.status = "addVehicle succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
            }
            )
            .addCase(addVehicle.rejected, (state, action) => {
                state.status = "addVehicle failed";
                state.loading = false;
                state.error = action.error.message;
            })
            // .addCase(getVehicleByCompLocWarhouse.pending, (state, action) => {
            //     state.status = "getVehicleByCompLocWarhouse loading";
            //     state.loading = true;
            // })
            // .addCase(getVehicleByCompLocWarhouse.fulfilled, (state, action) => {
            //     state.status = "getVehicleByCompLocWarhouse succeeded";
            //     state.loading = false
            //     state.VehicleByCompLocWarehouse = action.payload.results;
            // }
            // )
            // .addCase(getVehicleByCompLocWarhouse.rejected, (state, action) => {
            //     state.status = "getVehicleByCompLocWarhouse failed";
            //     state.loading = false;
            //     state.error = action.error.message;
            // })
            // .addCase(getVehicleByLocWarhouse.pending, (state, action) => {
            //     state.status = "getVehicleByLocWarhouse loading";
            //     state.loading = true;
            // })
            // .addCase(getVehicleByLocWarhouse.fulfilled, (state, action) => {
            //     state.status = "getVehicleByLocWarhouse succeeded";
            //     state.loading = false
            //     state.VehicleByLocWarehouse = action.payload.results;
            // }
            // )
            // .addCase(getVehicleByLocWarhouse.rejected, (state, action) => {
            //     state.status = "getVehicleByLocWarhouse failed";
            //     state.loading = false;
            //     state.error = action.error.message;
            // })
            // .addCase(getVehicleByWarhouse.pending, (state, action) => {
            //     state.status = "getVehicleByWarhouse loading";
            //     state.loading = true;
            // })
            // .addCase(getVehicleByWarhouse.fulfilled, (state, action) => {
            //     state.status = "getVehicleByWarhouse succeeded";
            //     state.loading = false
            //     state.VehicleByWarehouse = action.payload.results;
            // }
            // )
            // .addCase(getVehicleByWarhouse.rejected, (state, action) => {
            //     state.status = "getVehicleByWarhouse failed";
            //     state.loading = false;
            //     state.error = action.error.message;
            // })
    }

});
export const {
    InputChangeValue,
    SetVehicleParams,
    isModelVisible,
    setLocWarehouseList,
} = VehicleSlice.actions;

export default VehicleSlice.reducer;