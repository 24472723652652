import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";
const seralizeParams = (payload) => {
	var params = {};
	for (const k in payload) {
		if (Object.hasOwnProperty.call(payload, k)) {
			if (k === "type") {
				params.type = payload.type.join(",");
			} else {
				if (payload[k] !== "" && k !== "no_of_pages") {
					params[k] = payload[k];
				}
			}
		}
	}
	return params;
};
export const getLotTransferInList = createAsyncThunk(
    "/getLotTransferInList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/lottransferin/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getLotTransferInByID = createAsyncThunk(
    "/getLotTransferInByID",
    async (payload) => {
        try {
            const response = await getList("/transfers/lottransferin/" + payload + "/");
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);
export const getLotTransferIn = createAsyncThunk(
	"/getLotTransferIn",
	async (payload) => {
		// const { to_warehouse, ...params } = payload;
		var serializedParams = seralizeParams(payload);
		try {
			const response = await getParamsList(
				`transfers/lottransferout/mini/`, // Append ID in the URL
				serializedParams
			);

			if (response) {
				return { response}; // Return ID separately if needed
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const LotTransferInAdd = createAsyncThunk(
    "/LotTransferInAdd",
    async (payload) => {

        try {
            const response = await postAdd("/transfers/lottransferin/", payload);
            if (response.status === 201) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Internal Transfer In Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return { response, modal: false };
            }
            else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to add Internal Transfer In. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                             {error.response.data.error[0]}
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const LotTransferInEdit = createAsyncThunk(
    "/LotTransferInEdit",
    async (payload) => {
        try {
            const { obj} = payload;
            const response = await postEdit("/transfers/lottransferin/" + payload.id + "/", obj);
            if (response.status === 200) {

                return { response, active: false };
            } else {
                throw new Error("Sorry! Unable to update Internal Transfer Out. Please try again!")
            }
        } catch (err) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {err.message}
                        </p>
                    </div>
                ),
            });
            throw err;
        }
    }
);
export const LotTransferInDelete = createAsyncThunk(
    "/LotTransferInDelete",
    async (payload, { dispatch }) => {
        try {
            const response = await postDelete("/transfers/lottransferin/" + payload.id +"/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Lot Transfer In has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Lot Transfer In has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                             {error.response.data.error[0]}
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);